import { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import logoWithName from "../assets/logo-text.png";
import logo from "../assets/logo-solo.png";
import { Link, useLocation } from "react-router-dom";
import { ProjectsMenu } from "./ProjectsMenu";
import { ROUTE_URI } from "../utils/RouteLinks";
import { LanguageSelector } from "./LanguageSelector";
import { useTranslation } from "react-i18next";

const navigation = [
  { name: "One Hand", translationKey: "main", href: ROUTE_URI.HOME },
  {
    name: "Stöd oss",
    translationKey: "contribute",
    href: ROUTE_URI.CONTRIBUTIONS,
  },
  { name: "Projekt", translationKey: "projects", href: ROUTE_URI.PROJECTS },
  { name: "Om oss", translationKey: "about", href: ROUTE_URI.ABOUT },
  { name: "Stadgar", translationKey: "statues", href: ROUTE_URI.STATUES },
  { name: "Kontakta oss", translationKey: "contact", href: ROUTE_URI.CONTACT },
];

export const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  let backgroundColor = "bg-white";
  let textColor = { main: "text-gray-900", subContent: "text-gray-900" };
  if (pathname === ROUTE_URI.HOME || pathname === ROUTE_URI.NOT_FOUND) {
    backgroundColor = "";
    textColor = { main: "text-white", subContent: "text-gray-900" };
  }

  return (
    <>
      <header className={`absolute inset-x-0 top-0 z-50 ${backgroundColor}`}>
        <nav
          className="mx-auto flex max-w-7xl items-center p-6 justify-between lg:px-8"
          aria-label="Global"
        >
          <div className="flex md:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Öppna meny</span>
              <Bars3Icon
                className="h-6 w-6 stroke-indigo-400"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="flex">
            <Link to={ROUTE_URI.HOME} className="-m-1.5 p-1.5">
              <span className="sr-only">One Hand</span>
              <img
                className="h-14 w-auto"
                src={logoWithName}
                alt="navbar-logo"
              />
            </Link>
          </div>
          <div className="hidden md:flex md:gap-x-6 lg:gap-x-12 md:justify-center">
            {navigation.map((item) => {
              if (item.name === "Projekt") {
                return (
                  <>
                    <ProjectsMenu
                      textColor={textColor}
                      extraTextClasses={"text-sm font-semibold leading-6"}
                      wrapperClasses
                    />
                  </>
                );
              }
              return (
                <Link
                  className={`text-sm font-semibold leading-6 ${textColor.main}`}
                  to={item.href}
                >
                  {t(`navbar.${item.translationKey}`)}
                </Link>
              );
            })}
          </div>
          <div className="m-2.5 inline-flex items-center justify-center rounded-md p-2.5">
          <LanguageSelector />
          </div>
        </nav>
        <Dialog
          as="div"
          className="md:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">One Hand</span>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt="logo in open mobile-menu"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Stäng meny</span>
                <XMarkIcon
                  className="h-6 w-6 stroke-indigo-400"
                  aria-hidden="true"
                />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => {
                    if (item.name === "Projekt") {
                      return (
                        <>
                          <ProjectsMenu
                            closeMobileMenu={() => setMobileMenuOpen(false)}
                            textColor={{ ...{ main: "text-white" }, textColor }}
                            extraTextClasses={
                              "text-base font-semibold leading-7"
                            }
                            wrapperClasses={
                              "-mx-3 block rounded-lg px-3 py-2 hover:bg-gray-400"
                            }
                          />
                        </>
                      );
                    }
                    return (
                      <Link
                        to={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-400"
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
};
