import React from "react";
import bihLogo from '../assets/sponsor-logos/BiH.png'
import habilLogo from '../assets/sponsor-logos/habil-jurist.jpg'
import tolkFyrenLogo from '../assets/sponsor-logos/tolk-fyren.png'
import dentalisternaLogo from '../assets/sponsor-logos/dentalisterna-logo-small.png'
import sverostLogo from '../assets/sponsor-logos/sverost-logo.png'
import hackawayLogo from '../assets/sponsor-logos/hackaway-high-resolution-logo.png'
import luxiaLogo from '../assets/sponsor-logos/luxia.jpeg'
import lyckanAttGeLogo from '../assets/sponsor-logos/lyckan-att-ge.jpg'
import slmaLogo from '../assets/sponsor-logos/SLMA-logo-large.png'
import marcusLogo from '../assets/sponsor-logos/marcus-logo.png'
import atteaLogo from '../assets/sponsor-logos/attea-logo.jpg'
import icaMaxiLogo from '../assets/sponsor-logos/ica-maxi-botkyrka.png'
import jacobInvestLogo from '../assets/sponsor-logos/jacob-invest.png'
import SponsorField, {SponsorImageProps} from "../components/SponsorField";
import {useTranslation} from "react-i18next";


const sponsors: SponsorImageProps[] = [
    {src: bihLogo, alt: 'Barn i hjärtat', href: 'https://www.barnihjartat.se/'},
    {src: habilLogo, alt: 'Habil', href: 'https://www.habil.se/'},
    {src: tolkFyrenLogo, alt: 'Tolkfyren', href: 'https://tolkfyren.se/'},
    {src: sverostLogo, alt: 'Sveriges Rosteri', href: 'https://sverost.nu/'},
    {src: hackawayLogo, alt: 'Hackaway AB', href: ''},
    {src: undefined, alt: 'SZ Redovisning AB', href: ''},
    {src: slmaLogo, alt: 'Swedish Lebanese Medical Association', href: 'https://www.slma.se/'},
    {src: undefined, alt: 'Vivianne Skrädderi & Kem', href: 'https://kemtvattsguiden.se/vivian-skraddare'},
    {src: marcusLogo, alt: 'Marcus Autocenter', href: 'https://www.autobutler.se/bilverkstad/14343-vaarby/martins-autocenter-ab/'},
    {src: undefined, alt: 'Alby macken', href: ''},
    {src: luxiaLogo, alt: 'Luxia AB', href: 'https://www.instagram.com/charcoalcompany/'},
    {src: undefined, alt: 'Fridas Klippotek', href: 'https://www.fridasklippotek.se/'},
    {src: lyckanAttGeLogo, alt: 'Lyckan att ge', href: 'https://www.facebook.com/gelyckan/', showName: true},
    {src: undefined, alt: 'NT Trading', href: 'https://nt-trading.se/'},
    {src: dentalisternaLogo, alt: 'Dentalisterna', href: 'https://www.dentalisterna.se/'},
    {src: atteaLogo, alt: 'Ateljé Attea', href: 'https://www.attea.se/'},
    {src: jacobInvestLogo, alt: 'Jacob Invest', href: ''},
    {src: icaMaxiLogo, alt: 'Ica Maxi Botkyrka', href: 'https://www.ica.se/butiker/maxi/botkyrka/maxi-ica-stormarknad-botkyrka-1004407/'},
]

export default function SponsorsSection() {
    const { t } = useTranslation()
    return (
        <div className="bg-white">
            <h1 className="text-center text-3xl font-semibold leading-16 text-gray-600">
                {t('mainPage.sponsorSection.sponsorsHeader')}
            </h1>
            <h3 className="text-center my-6 text-lg font-semibold leading-8 text-gray-500">
                {t('mainPage.sponsorSection.sponsorsText.0')} <br />
                {t('mainPage.sponsorSection.sponsorsText.1')}
            </h3>
            <div className="mx-auto">
                <div className="grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-3">
                    {sponsors.map((sponsor, index) => SponsorField(sponsor, index))}
                </div>
            </div>
        </div>
    )
}
