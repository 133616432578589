import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const statNumbers = [455, 480000, 1786000, 0];
export const Stats = ({ ref }) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className="bg-white">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {t("mainPage.ourMissionHeader")}
      </h2>
      <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
        <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
          <p className="text-xl leading-8 text-gray-600">
            <b>OneHand</b> {t("mainPage.statisticsTexts.0")}
          </p>
          <p className="mt-10 text-xl leading-8 text-gray-600">
            {t("mainPage.statisticsTexts.1")}
          </p>
          <p className="mt-10 text-xl leading-8 text-gray-600">
            {t("mainPage.statisticsTexts.2")}
          </p>
        </div>
        <div className="lg:flex lg:flex-auto lg:justify-center">
          <dl className="w-96 space-y-8 xl:w-96">
            {t("mainPage.statistics", { returnObjects: true }).map(
              (stat, index) => (
                <div key={index} className="flex flex-col-reverse gap-y-4">
                  <dt className="text-base leading-7 text-gray-600">
                    {stat.text}
                  </dt>
                  <dd className="text-4xl font-semibold tracking-tight text-gray-900">
                    <CountUp
                      end={statNumbers[index]}
                      duration={2}
                      suffix={stat.suffix}
                      enableScrollSpy={true}
                    />
                  </dd>
                </div>
              )
            )}
          </dl>
        </div>
      </div>
    </div>
  );
};
